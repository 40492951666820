import React from 'react';
import Card from './Card';
import Share from './Share';

const cards = [];
const addCard = (color, scandal, excuse) => {
  cards.push({
    color,
    excuse,
    scandal,
  })
}

addCard('#bc6ca7', 'Gaswinning Groningen', 'We hebben onderschat wat voor opgave hier voor ons lag');
addCard('#e34132', 'Memo over afschaffing dividend-belasting', 'Ik heb er simpelweg geen actieve herinnering aan');
addCard('#6ca0dc', 'Veroordeling van Rutte voor discriminatie', 'Ik zou de brief nu weer opsturen');
addCard('#645394', 'Rol van Rutte bij bonnetjesaffaire', 'Ik heb aan dat telefoongesprek geen concrete herinnering');
addCard('#944743', 'Belofte van €1000 aan iedere burger', 'Ik heb beloftes gedaan die ik niet had mogen maken.');
addCard('#00a68c', 'Toeslagen-affaire', 'Ik heb geen directe verantwoordelijk-heid voor dit beleid.');
addCard('#6c4f3d', 'Structurele discriminatie op de arbeidsmarkt', 'Wij als overheid kunnen daar niks aan doen.');
addCard('#1a3a13', 'Ruttedoctrine', 'Mijn denken is opgeschoven.');

const initializeGame = (setGame) => {
  const newGame = []
  cards.forEach((card, index) => {
    const firstOption = {
      id: 2 * index,
      matchId: index,
      color: card.color,
      flipped: false,
      text: `"${card.excuse}"`,
    }
    const secondOption = {
      id: 2 * index + 1,
      matchId: index,
      color: card.color,
      flipped: false,
      text: card.scandal,
    }

    newGame.push(firstOption)
    newGame.push(secondOption)
  });

  const shuffledGame = newGame.sort(() => Math.random() - 0.5)
  setGame(shuffledGame)
}

function MemoryGame({
  setFinished,
}) {
  const [game, setGame] = React.useState([])
  const [flippedCount, setFlippedCount] = React.useState(0)
  const [flippedIndexes, setFlippedIndexes] = React.useState([])

  React.useEffect(() => {
    initializeGame(setGame);
  }, [])

  React.useEffect(() => {
    const finished = !game.some(card => !card.flipped);
    if (finished && game.length > 0) {
      setTimeout(() => {
        setFinished(true);
      }, 1000);
    }
  }, [game])

  if (flippedIndexes.length === 2) {
    const match = game[flippedIndexes[0]].matchId === game[flippedIndexes[1]].matchId;

    if (match) {
      const newGame = [...game];
      newGame[flippedIndexes[0]].flipped = true;
      newGame[flippedIndexes[1]].flipped = true;
      setGame(newGame);

      const newIndexes = [...flippedIndexes];
      newIndexes.push(false);
      setFlippedIndexes(newIndexes);
    } else {
      const newIndexes = [...flippedIndexes];
      newIndexes.push(true);
      setFlippedIndexes(newIndexes);
    }
  }

  if (game.length === 0) {
    return <div>Laden...</div>;
  } else {
    return (
      <React.Fragment>
        <h3>Weet jij nog welk excuus hoorde bij welk schandaal?</h3>
        <div id="cards">
          {game.map((card, index) => (
            <div className="card" key={index}>
              <Card
                id={index}
                color={card.color}
                game={game}
                text={card.text}
                flippedCount={flippedCount}
                setFlippedCount={setFlippedCount}
                flippedIndexes={flippedIndexes}
                setFlippedIndexes={setFlippedIndexes}
              />
            </div>
          ))}
        </div>
        <Share />
      </React.Fragment>
    )
  }
}

export default MemoryGame;
