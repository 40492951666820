import React from 'react';
import { animated, useTransition } from 'react-spring';
import Share from './Share';

const excuses = [
  'We hebben onderschat wat voor opgave hier voor ons lag.',
  'Ik heb geen actieve herinnering aan afradende memo\'s.',
  'Ik zou dat nu weer zo doen.',
  'Ik heb aan dat telefoongesprek geen concrete herinnering.',
  'Ik heb beloftes gedaan die ik niet had mogen maken.',
  'Ik heb geen directe verantwoordelijkheid voor dit beleid.',
  'Wij als overheid kunnen daar niks aan doen.',
  'Mijn denken is opgeschoven.',
];
const scandals = [
  '... blijkt dat er naast een Toeslagenaffaire ook nog een Bijstandsaffaire en een Huurtoeslagaffaire op ons wacht.',
  '... blijkt dat de door de overheid gestelde klimaatdoelen weer niet worden bereikt.',
  '... het delen van persoonsgegevens door de Wet gemeentelijke schuldhulpverlening leidt tot grootschalige privacy schendingen.',
  '... steeds meer jongeren dakloos blijken te zijn geraakt door associaal beleid.',
  '... de klimaatcrisis "opeens wel heel dichtbij" blijkt te komen.',
];

const FinishedGame = () => {
  const [scandal, setScandal] = React.useState(0);
  const pages = React.useMemo(() => (
    scandals.map((scandal, scandalIndex) => ({ style }) => (
      <animated.div className="scandal" key={`scandal-${scandalIndex}`} style={style}>
        <div>Welk excuus kan Mark binnenkort het best gebruiken als...</div>
        <h3>{scandal}</h3>
        {excuses.map((excuse, index) => (
          <div key={`excuse-${scandalIndex}-${index}`}>
            <label>
              <input
                type="checkbox"
                onClick={() => {
                  setTimeout(() => {
                    setScandal((previous) => previous + 1);
                  }, 200)
                }}
              />
              {excuse}
            </label>
          </div>
        ))}
      </animated.div>
    ))
  ), []);
  const transitions = useTransition(scandal, p => p, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  })

  if (scandal >= scandals.length) {
    return (
      <div>
        <Share />
      </div>
    );
  }

  return (
    <div>
      <h3>Goed gedaan! Help Mark Rutte zijn volgende crisis door.</h3>
      <div className="scandals">
        {transitions.map(({ item, props, key }) => {
          const Page = pages[item];
          return <Page key={key} style={props} />
        })}
      </div>
      <div className="bullets">
        {scandals.map((_s, index) => <div className={`bullet ${index === scandal ? 'bullet-active' : ''}`} />)}
      </div>
    </div>
  );
}

export default FinishedGame;
