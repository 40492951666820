import React from 'react';

const Share = () => (
  <React.Fragment>
    <h3>Deel Marks geheugentrainer.</h3>
    <div className="btn__container">
      <a href="https://twitter.com/intent/tweet/?text=Heb%20jij%20een%20beter%20geheugen%20dan%20onze%20minister-president?%20Test%20het%20nu%20op%20https://marksgeheugentrainer.nl%20%23marksgeheugen" className="btn btn-twitter" rel="noopener noreferrer" target="_blank">
        <i className="fa fa-twitter" />
        <span>twitter</span>
      </a>
      <a href="https://api.whatsapp.com/send?text=Heb%20jij%20een%20beter%20geheugen%20dan%20onze%20minister-president?%20Test%20het%20nu%20op%20https://marksgeheugentrainer.nl" className="btn btn-whatsapp" rel="noopener noreferrer" target="_blank">
        <i className="fa fa-whatsapp" />
        <span>whatsapp</span>
      </a>
      <a href="https://facebook.com/sharer/sharer.php?u=https://marksgeheugentrainer.nl" className="btn btn-f" rel="noopener noreferrer" target="_blank">
        <i className="fa fa-facebook" />
        <span>facebook</span>
      </a>
    </div>
  </React.Fragment>
);

export default Share;
