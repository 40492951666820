import React from 'react';
import './App.css';
import FinishedGame from './FinishedGame';
import MemoryGame from './MemoryGame';

function App() {
  const [finished, setFinished] = React.useState(false);

  return (
    <div>
      <h1>Marks Geheugentrainer.</h1>
      {finished ? <FinishedGame /> : <MemoryGame setFinished={setFinished} />}
    </div>
  )
}

export default App;
